import { useEffect, useState } from 'react';
import moment from 'moment';

interface GitHubStats {
    followers: number;
    following: number;
    public_repos: number;
    bio: string;
    avatar_url: string;
    name: string;
    login: string;
    html_url: string;
    created_at: Date;
}

export function GitHubCard() {
    const [stats, setStats] = useState<GitHubStats | null>(null);

    useEffect(() => {
        fetch('https://api.github.com/users/ItzDabbzz')
            .then(res => res.json())
            .then(data => setStats(data));
    }, []);
    if (!stats) return null;

    const date = moment(stats.created_at);
    const formattedDate = date.format('MMMM Do YYYY');

    return (
        <div className="bg-gray-900/95 rounded-xl w-full max-w-3xl mx-auto shadow-lg shadow-purple-500/10 ring-1 ring-gray-700/50 border border-gray-800">
            <div className="flex items-center gap-2 px-4 py-3 bg-gray-800/50 border-b border-gray-700">
                <div className="w-3 h-3 rounded-full bg-red-500"></div>
                <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
                <div className="w-3 h-3 rounded-full bg-green-500"></div>
                <span className="text-gray-400 text-sm ml-2">github@profile:~$</span>
            </div>

            <div className="p-4">
                <div className="mt-3 grid grid-cols-2 gap-5">
                    <div className="flex justify-center items-center">
                        <img
                            src={stats.avatar_url}
                            alt={stats.name}
                            className="w-16 h-16 rounded-full ring-2 ring-blue-500"
                        />
                    </div>
                    <div className="text-center">
                        <h2 className="text-lg font-bold text-white">{stats.name}</h2>
                        <p className="text-sm text-gray-400">@{stats.login}</p>
                    </div>
                    <div className="text-center">
                        <p className="text-lg font-bold text-white">{stats.followers}</p>
                        <p className="text-xs text-gray-400">Followers</p>
                    </div>
                    <div className="text-center">
                        <p className="text-lg font-bold text-white">{stats.following}</p>
                        <p className="text-xs text-gray-400">Following</p>
                    </div>
                    <div className="text-center">
                        <p className="text-lg font-bold text-white">{stats.public_repos}</p>
                        <p className="text-xs text-gray-400">Repositories</p>
                    </div>
                    <div className="text-center">
                        <p className="text-lg font-bold text-white">{formattedDate}</p>
                        <p className="text-xs text-gray-400">Joined</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
