import type { Route } from "./+types/home";
import { Welcome } from "../components/welcome/welcome";

export const meta = () => {
  return [
      { title: "Dabz's Site" },
      { name: "description", content: "Davin aka ItzDabbzz (Dabz for short)'s personal website" },
      { property: "og:title", content: "Dabz's Site" },
      { property: "og:description", content: "Davin aka ItzDabbzz (Dabz for short)'s personal website" },
      { property: "og:image", content: "/dabz.png" },
      { property: "og:url", content: "https://itzdabbzz.me/" },
      { property: "og:type", content: "website" },
      { name: "theme-color", content: "#cba6f7" },
      { name: "twitter:card", content: "summary_large_image" },
  ];
};

export default function Home() {
  return <Welcome />;
}
