import { motion } from 'framer-motion';
import { FaReact, FaNodeJs, FaDocker, FaPython } from 'react-icons/fa';
import { SiTypescript, SiJavascript, SiLua, SiGo, SiRust, SiPnpm } from 'react-icons/si';

const technologies = [
    {
        name: 'JavaScript',
        icon: <SiJavascript className="text-4xl" />,
        color: 'text-yellow-400',
        proficiency: 70,
        experience: '7 years'
    },
    {
        name: 'TypeScript',
        icon: <SiTypescript className="text-4xl" />,
        color: 'text-blue-400',
        proficiency: 45,
        experience: '3 years'
    },
    {
        name: 'React',
        icon: <FaReact className="text-4xl" />,
        color: 'text-cyan-400',
        proficiency: 45,
        experience: '2 years'
    },
    {
        name: 'Node.js',
        icon: <FaNodeJs className="text-4xl" />,
        color: 'text-green-400',
        proficiency: 85,
        experience: '5 years'
    },
    {
        name: 'pnpm',
        icon: <SiPnpm className="text-4xl" />,
        color: 'text-blue-400',
        proficiency: 85,
        experience: '4 years'
    },
    {
        name: 'Python',
        icon: <FaPython className="text-4xl" />,
        color: 'text-yellow-300',
        proficiency: 80,
        experience: '3 years'
    },
    {
        name: 'Docker',
        icon: <FaDocker className="text-4xl" />,
        color: 'text-blue-500',
        proficiency: 75,
        experience: '5 years'
    },
    {
        name: 'Docker Compose',
        icon: <FaDocker className="text-4xl" />,
        color: 'text-blue-400',
        proficiency: 65,
        experience: '5 years'
    },
    {
        name: 'Lua',
        icon: <SiLua className="text-4xl" />,
        color: 'text-blue-600',
        proficiency: 100,
        experience: '6 years'
    },
    {
        name: 'Go',
        icon: <SiGo className="text-4xl" />,
        color: 'text-cyan-500',
        proficiency: 5,
        experience: '2 years'
    },
    {
        name: 'Rust',
        icon: <SiRust className="text-4xl" />,
        color: 'text-orange-500',
        proficiency: 5,
        experience: '2 years'
    }
];

export function Technologies() {
    return (
        <div className="flex flex-wrap justify-center gap-8 mt-16">
            {technologies.map((tech, index) => (
                <motion.div
                    key={tech.name}
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ delay:  0.01 }}
                    whileHover={{ scale: 1.1 }}
                    className="bg-gray-900/95 p-6 rounded-xl w-64
            shadow-lg shadow-purple-500/10
            inset-shadow-lg inset-shadow-purple-500/5
            ring-1 ring-gray-700/50
            inset-ring-1 inset-ring-purple-400/10
            flex flex-col items-center gap-3"
                >
                    <div className={tech.color}>{tech.icon}</div>
                    <span className="text-gray-300 font-medium">{tech.name}</span>

                    <div className="w-full bg-gray-800 rounded-full h-2.5 mt-2">
                        <motion.div
                            initial={{ width: 0 }}
                            animate={{ width: `${tech.proficiency}%` }}
                            transition={{ duration: 1, delay: index * 0.1 }}
                            className="bg-gradient-to-r from-purple-400 to-pink-400 h-2.5 rounded-full"
                        />
                    </div>

                    <div className="text-sm text-gray-400">
                        {tech.proficiency}% • {tech.experience}
                    </div>
                </motion.div>
            ))}
        </div>
    );
}
