import { motion } from 'framer-motion';
import { image } from 'framer-motion/client';
import { FaGithub, FaExternalLinkAlt } from 'react-icons/fa';

const projects = [
    {
      title: 'zdiscord',
      description: 'A Discord bot that runs in FiveM for the purpose of whitelisting, moderation and utilties using discord.js',
      tech: ['Node.JS', 'Discord.Js', 'JavaScript', 'Lua', 'FiveM'],
      image: 'https://repository-images.githubusercontent.com/375876850/fc7036d1-9954-4514-8edc-783f89e124f5',
      links: {
        github: 'https://github.com/zfbx/zdiscord',
        docs: 'https://zfbx.github.io/zdiscord/'
      }
    },
    {
      title: 'XBackBone',
      description: 'XBackBone is a simple and lightweight PHP file manager that support the instant sharing tool ShareX',
      tech: [ 'PHP', 'JavaScript', 'Twig' ],
      image: 'https://github.com/sergix44/XBackBone/raw/master/.github/xbackbone.png',
      links: {
        github: 'https://github.com/sergix44/XBackBone',
        docs: 'https://xbackbone.app/'
      }
    }
    //TODO: Add more projects
  ];

export function Projects() {
  return (
    <section className="py-20 px-4">
      <div className="max-w-7xl mx-auto">
        <motion.h2 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="text-3xl font-bold bg-gradient-to-r from-purple-400 to-pink-400 bg-clip-text text-transparent mb-12 text-center"
        >
          Featured Projects
        </motion.h2>
        <motion.h1 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="text-xl font-bold bg-gradient-to-r from-purple-400 to-pink-400 bg-clip-text text-transparent mb-12 text-center"
        >
          Personal & Favorites
        </motion.h1 >
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {projects.map((project, index) => (
                  <motion.div
                    key={project.title}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                    whileHover={{ y: -5 }}
                    className="bg-gray-950/95 backdrop-blur-sm rounded-xl overflow-hidden
            shadow-lg shadow-purple-500/10
            inset-shadow-lg inset-shadow-purple-500/5
            ring-1 ring-gray-700/50
            inset-ring-1 inset-ring-purple-400/10
            border border-gray-800"
                  >
                    <div className="h-48 bg-gray-800 relative overflow-hidden">
                      <img
                        src={project.image}
                        alt={project.title}
                        className="object-cover w-full h-full transform hover:scale-110 transition-transform duration-300"
                      />
                    </div>

                    <div className="p-6">
                      <h3 className="text-xl font-semibold text-gray-100 mb-2">{project.title}</h3>
                      <p className="text-gray-400 mb-4">{project.description}</p>

                      <div className="flex flex-wrap gap-2 mb-4">
                        {project.tech.map((tech) => (
                          <span
                            key={tech}
                            className="px-3 py-1 bg-gray-900 rounded-full text-sm text-purple-400 
                    ring-1 ring-purple-400/20"
                          >
                            {tech}
                          </span>
                        ))}
                      </div>

                      <div className="flex gap-4">
                        <a
                          href={project.links.github}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-gray-300 hover:text-purple-400 transition-colors duration-200"
                        >
                          <FaGithub className="text-xl" />
                        </a>
                        <a
                          href={project.links.docs}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-gray-300 hover:text-purple-400 transition-colors duration-200"
                        >
                          <FaExternalLinkAlt className="text-xl" />
                        </a>
                      </div>
                    </div>
                  </motion.div>
                ))}
        </div>
      </div>
    </section>
  );
}
