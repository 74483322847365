import { Technologies } from './technologies';
import { Projects } from './projects';
import { Terminal } from './terminal';
import { GitHubCard } from './github-card';
import '../../styles/out.css';

export function Welcome() {
  return (
    <main className="min-h-screen bg-gray-950">
      <section className="pt-20 pb-32 px-4">
        <div className="max-w-7xl mx-auto">
          <Terminal />
          <br/><br/><br/>
          <div className="mt-12">
            <GitHubCard />
          </div>
          <Projects />
          <Technologies />
        </div>
      </section>
    </main>
  );
}
