import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';

const welcomeText = [
    "Welcome to Dabz's Digital Space",
    "Full-Stack Developer | Tech Explorer | Problem Solver",
    "Type 'help' for available commands...",
];

export function Terminal() {
    const [displayedLines, setDisplayedLines] = useState<string[]>([]);
    const [currentLineIndex, setCurrentLineIndex] = useState(0);
    const [currentText, setCurrentText] = useState('');

    useEffect(() => {
        if (currentLineIndex < welcomeText.length) {
            const timer = setInterval(() => {
                const targetText = welcomeText[currentLineIndex];
                if (currentText.length < targetText.length) {
                    setCurrentText(targetText.slice(0, currentText.length + 1));
                } else {
                    setDisplayedLines([...displayedLines, currentText]);
                    setCurrentText('');
                    setCurrentLineIndex(currentLineIndex + 1);
                }
            }, 50);

            return () => clearInterval(timer);
        }
    }, [currentLineIndex, currentText, displayedLines]);

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-gray-900/95 rounded-xl w-full max-w-3xl mx-auto
        shadow-lg shadow-purple-500/10
        ring-1 ring-gray-700/50
        border border-gray-800
        overflow-hidden"
        >
            <div className="flex items-center gap-2 px-4 py-3 bg-gray-800/50 border-b border-gray-700">
                <div className="w-3 h-3 rounded-full bg-red-500"></div>
                <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
                <div className="w-3 h-3 rounded-full bg-green-500"></div>
                <span className="text-gray-400 text-sm ml-2">dabz@terminal:~$</span>
            </div>

            <div className="p-4 font-mono text-sm">
                {displayedLines.map((line, index) => (
                    <div key={index} className="text-gray-300">{line}</div>
                ))}
                {currentLineIndex < welcomeText.length && (
                    <div className="text-gray-300">
                        {currentText}
                        <span className="animate-pulse">▊</span>
                    </div>
                )}
            </div>
        </motion.div>
    );
}
